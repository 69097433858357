
export const environment = {
    production: true,
    SW: true,
    APIS: {
      WS_URL:'wss://soc.api.gch.0s.cl/ws',
      CENTRO_NOTIFICACIONES: 'https://gch.gw.api.gch.0s.cl/centro-notificacion',
      SOLICITUDES: 'https://me-api.0s.cl/solicitudes',
      USUARIOS: 'https://me-api.0s.cl/usuarios',
      COMUNES: 'https://me-api.0s.cl/comunes',
      EMPRESAS: 'https://me-api.0s.cl/empresas'
    },
    sso: {
      url: "https://sso-dev.0s.cl/auth",
      realm: "OTIC",
      clientId: "mantenedor-empresas",
      credentials: {
        secret: "bhY7lRj1VWwGctDcjJjU357KtXFLHHRG",
      },
    },
    AUTH_DOMAIN: 'https://conecta-qa.0s.cl/'
  };